<template>
  <el-container>
    <el-row style="width: 100%">
      <el-row>
        <el-col :span="24" style="margin-bottom:10px">
          <div class="cardTitle">小区列表</div>
          <el-button
            type="primary"
            @click="createCommunity()"
            class="cardTitleBtn"
            >新建小区</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-select
            style="width: 90%"
            v-model="searchDistrictGuid"
            @change="searchDirstrictChange()"
            placeholder="区"
          >
          <el-option :key="-1" :label="''" :value="''">
          </el-option>
            <el-option
              v-for="item in searchDistrictList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-select
            style="width: 90%"
            v-model="searchStreetGuid"
            placeholder="街道"
          >
          <el-option :key="-1" :label="''" :value="''"></el-option>
            <el-option
              v-for="item in searchStreetList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-input
            style="width: 90%"
            v-model="searchCommunityName"
            :placeholder="'小区'"
          ></el-input>
        </el-col>
        <el-col :span="3" style="padding-left: 20px">
          <el-button style="" @click="getCommnityList()" type="primary"
            >搜索</el-button
          >
        </el-col>
      </el-row>
      <el-col :span="24">
        <el-table
          :data="comunityList"
          :default-sort="{ prop: 'districtName', order: 'desc' }"
          style="width: 100%"
        >
          <el-table-column
            prop="districtName"
            label="区"
            sortable
          ></el-table-column>
          <el-table-column
            prop="streetName"
            label="街道"
            sortable
          ></el-table-column>
          <el-table-column
            prop="name"
            label="小区名称"
            sortable
          ></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click="getDetail(scope.row)" type="primary" size="mini">
                编辑
              </el-button>
              <el-button
                @click="onDelete(scope.row.guid)"
                type="danger"
                size="mini"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="新建或编辑" :visible.sync="dialogVisible" width="50%">
      <el-row>
        <el-col :span="8">
          <el-select
            v-model="dirstrictGuid"
            @change="dirstrictChange()"
            placeholder="区"
          >
            <el-option
              v-for="item in dirstrictList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select v-model="streetGuid" placeholder="街道">
            <el-option
              v-for="item in streetList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-input v-model="communityName" placeholder="小区名称"></el-input>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveOrUpdate()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      comunityList: [],
      dirstrictList: [],
      streetList: [],
      dialogVisible: false,
      dirstrictGuid: "",
      streetGuid: "",
      communityName: "",
      communityForm: {
        guid: "",
        status: true,
        name: "",
        streetGuid: "",
      },
      searchDistrictGuid: "",
      searchStreetGuid: "",
      searchCommunityName: "",
      searchDistrictList: [],
      searchStreetList: [],
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getCommnityList("", "", "");
    self.getDistrictList();
  },
  methods: {
    getCommnityList() {
      let self = this;
      self.$axios
        .get(
          "/community/list?districtGuid=" +
            self.searchDistrictGuid +
            "&streetGuid=" +
            self.searchStreetGuid+
            "&nameLike=" +
            self.searchCommunityName,
          {
            headers: { token: self.userInfo.token },
          }
        )
        .then(function (response) {
          self.comunityList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "小区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      self.dirstrictGuid = row.districtGuid;
      this.getStreetList(row.districtGuid, function () {
        self.streetGuid = row.streetGuid;
        self.communityName = row.name;
        self.communityForm.guid = row.guid;
        self.dialogVisible = true;
      });
    },
    createCommunity() {
      let self = this;
      self.dirstrictGuid = "";
      self.streetList = [];
      self.streetGuid = "";
      self.communityName = "";
      (self.communityForm = {
        guid: "",
        status: true,
        name: "",
        streetGuid: "",
      }),
        (self.dialogVisible = true);
    },
    getDistrictList() {
      let self = this;
      self.$axios
        .get("/district/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.dirstrictList = response.data.data;
          self.searchDistrictList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    dirstrictChange() {
      this.getStreetList(this.dirstrictGuid);
    },
    searchDirstrictChange() {
      let self = this;
      self.$axios
        .get("/street/list?districtGuid=" + self.searchDistrictGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.searchStreetList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "街道列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getStreetList(districtGuid, callback) {
      let self = this;
      self.$axios
        .get("/street/list?districtGuid=" + districtGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.streetList = response.data.data;
          callback();
        })
        .catch(function (error) {
          self.$message({
            message: "街道列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    saveOrUpdate() {
      let self = this;
      self.communityForm.name = self.communityName;
      self.communityForm.streetGuid = self.streetGuid;
      self.$axios
        .post("/community/saveOrUpdate", self.communityForm, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          if (response.data.status) {
            self.getCommnityList("", "", "");
            self.dialogVisible = false;
          }
        })
        .catch(function (error) {
          self.$message({
            message: "保存失败" + error.response.data.message,
            type: "warning",
          });
        });
    },
    onDelete(guid) {
      let self = this;
      self.$axios
        .delete("/community/delete", {
          data: { guid: guid },
          headers: { token: self.userInfo.token },
        })
        .then(function () {
          self.$message({
            message: "删除成功",
            type: "success",
          });
          self.getCommnityList("", "", "");
        })
        .catch(function (error) {
          self.$message({
            message: "删除失败" + error.response.data.message,
            type: "warning",
          });
        });
    },
  },
};
</script>

<style scoped>
</style>